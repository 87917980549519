.bio {
    max-width: 67.75rem;
    margin: auto;
    margin-top: 4rem;
    text-align: justify;
    font-size: 1.5rem;
    padding-inline: 2rem;
}

.bio p {
    line-height: 1.875;
}
@media screen and (max-width: 700px) {
    .bio p, a {
      font-size: 1rem;
    }
    .bio p {
        margin-top: 0;
    }
    .bio {
        margin-top: 0;
    }
}

.bio a {
    text-decoration: none;
    color: inherit;
    line-height: 1.875;
}

.bio a:hover {
    color: inherit;
    text-decoration: none;
    line-height: 1.875;
}