:root {
    --avatar-height: 200px;
    --max-width: 67.75rem;
}
.header {
    display: flex;
    max-width: var(--max-width);
    margin: auto;
    margin-top: 2.5rem;
    align-items: center;
    padding-inline: 2rem;
}
.header img {
    width: var(--avatar-height);
    height: var(--avatar-height);
    border-radius: 100px;
}
.details {
    font-family: "Bebas Neue";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: var(--avatar-height);
    margin-left: 2rem;
}
.details h1 {
    font-size: 3.75rem;
    margin-block: 0;
    font-weight: 500;
    margin-bottom: -0.75rem;
}
.details h2 {
    letter-spacing: 0.11rem;
    font-size: 1.5rem;
    margin-block: 0;
    font-weight: 500;
}

.links {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--avatar-height);
}

@media screen and (max-width: 700px) {
    .header {
        flex-direction: column;
    }
    .header img {
        width: max(40vw, 200px);
        height: max(40vw, 200px);
        border-radius: max(20vw, 100px);
    }
    .details {
        align-items: center;
        margin-left: 0;
    }
    .links {
        flex-direction: row;
        margin-left: 0;
        gap: 2rem;
        height: auto;
        margin-top: 0.5rem;
    }
}

.heightPlaceholder {
    height: calc(200px + (200px - 6.75rem)/5);
}
.topBar {
    position: fixed;
    z-index: 2;
    width: calc(100vw - 4rem);
    height: 110px;
    top: 0;
    background-color: #F7F8FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 500ms;
}
.topBar img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.topBar h1 {
    font-family: 'Bebas Neue';
    font-size: 3rem;
    font-weight: 500;
}

.mobileClicker {
    display: flex;
    justify-content: center;
    align-items: flex-end;

}
.triangleDown {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 15px solid #353338;
    transform: translateY(-40px);
    transition: 500ms opacity;
}