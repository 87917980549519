.heading {
    font-weight: 300;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    font-size: 2.3rem;
}

.projectsWrapper {
    margin: auto;
    max-width: 67.75rem;
    padding-inline: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-between;
}

.project {
    flex-basis: 24rem;
    flex-grow: 1;
}
.project:hover {
    cursor: pointer;
}
.project p {
    text-align: justify;
    font-size: 1.35rem;
    line-height: 2;
}
.project img {
    grid-area: container;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #00000025;
    filter: saturate(30%);
}

@media screen and (max-width: 700px) {
    .project p {
      font-size: 1rem;
    }
    .project img {
        width: 50px;
        height: 50px;
    }
}